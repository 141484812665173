import { Col, Row, Button, Form, Accordion  } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileImport, faPaperPlane, faSearch, faTimes, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import { useState } from 'react';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { useForm } from "react-hook-form";
import SearchGrid from "./SearchGrid";

const UserList = () => {

    const [searchList, setSearchList] = useState(false);
    const [searchButtonClicked, setSearchButtonClicked] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const { register, handleSubmit, setValue} = useForm();

    const heading = "Users";
    const API_URL = process.env.REACT_APP_API_URL;
    const [showOnlyRealUsers, setShowOnlyRealUsers] = useState(false);
    const clearSearchFilters = () => {
        setValue('FName','');
        setValue('SName','');
        setValue('Email','');
        setValue('showOnlyRealUsers',false);
        setShowOnlyRealUsers(false);
    }

    const [searchFormData, setSearchFormData] = useState([{FName: "", SName: "", Email: "", ShowOnlyRealUsers: false}]);
   
    const onSearchSubmit = postedData => {
        /*if ((postedData.FName === '') && (postedData.SName === '') && (postedData.Email === '')){
            return false;
        }*/
        
       // console.log("inside onSearchSubmit", postedData);
        setSearchButtonClicked(true);
        let tmp = {};
        tmp["FName"] =  postedData.FName;
        tmp["SName"] =  postedData.SName;
        tmp["Email"] =  postedData.Email;
        tmp["ShowOnlyRealUsers"] = postedData.showOnlyRealUsers; 
        setSearchFormData([tmp]);
       
        if (searchList){
            setSearchList(false);
        }else{
            setSearchList(true);
        }
       
       /* ReactDOM.render(

            <SearchGrid searchList={searchList} postedData={postedData} />,
            document.getElementById('searchResults')
        );*/

    }   

    const exportCSV = () => {
        setIsGenerating(true); // Change button text to 'Generating...'
        
        var formData = new FormData();
        
        fetch(API_URL + '/v1/user/data/generate_csv', { 
            method: 'POST', 
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),                    
            }, 
            body: formData 
        })
        .then(response => response.json())
        .then(jsondata => {              
            if (jsondata.error) {
                alert("Something went wrong, please try again");
            } else if (jsondata.message === 'EXPORT_SUCCESS') {
                if (jsondata.csv_link) {
                    const csvUrl = API_URL + jsondata.csv_link;
                    const link = document.createElement('a');
                    link.href = csvUrl;
                    link.setAttribute('download', 'exported_data.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        })
        .catch((err) => {                
            console.log("Error: " + err);
        })
        .finally(() => {
            setIsGenerating(false); // Revert button text back to 'Export users' after the process is done
        });
    };
    

    return ( 
        <> 
            <ModuleBreadcrumb links={[{ label: heading }]}  />
            <h4>{heading}</h4>
            <Row >
                <Col xs={12}>
                    <Button as={Link} variant="primary" className="animate-hover" to={Routes.Users.addpath}>
                        <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                        Add new user
                    </Button> 
                    <Button as={Link} className="animate-hover btn-info" to={Routes.Users.importpath}>
                        <FontAwesomeIcon icon={faFileImport} className="animate-left-3 me-3 ms-2" />
                        Import users
                    </Button>
                    <Button as={Link} className="animate-hover btn-addnew" to={Routes.userInviteSend.path}>
                        <FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" />
                        Send invites
                    </Button> 
                    <Button className="animate-hover btn-export" onClick={exportCSV}>
                        <FontAwesomeIcon icon={faFileExport} className="animate-left-3 me-3 ms-2" />
                        {isGenerating ? 'Generating...' : 'Export users'}
                    </Button> {/* New Export Users Button */}             
                </Col>
            </Row>
            
            <Accordion  className="mt-4 mb-4">                        
                <Accordion.Item >
                    <Accordion.Header>Advanced search</Accordion.Header>
                    <Accordion.Body>
                        <Form className="mt-4" onSubmit={handleSubmit(onSearchSubmit)}>
                            <Row className="mt-3">
                                <Col className="col-12 col-md-4">
                                    <Form.Group id="FName">
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control type="text" placeholder="" {...register("FName")} />
                                    </Form.Group>                                    
                                </Col>  
                                <Col className="col-12 col-md-4">
                                    <Form.Group id="SName">
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control type="text" placeholder="" {...register("SName")} />
                                    </Form.Group>                                    
                                </Col>  
                                <Col className="col-12 col-md-4">
                                    <Form.Group id="Email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" placeholder="" {...register("Email")} />
                                    </Form.Group>                                    
                                </Col>                   
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-6">
                                    <Form.Check
                                        type="checkbox"
                                        label="Show only real users" {...register("showOnlyRealUsers")}
                                        checked={showOnlyRealUsers}
                                        onChange={() => setShowOnlyRealUsers(!showOnlyRealUsers)} // Toggle checkbox state
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-6">
                                    <Button variant="primary" type="submit"  className="animate-hover" >
                                        <FontAwesomeIcon icon={faSearch} className="animate-left-3 me-3 ms-2" />
                                        Search</Button>
                                    <Button variant="secondary"  className="animate-hover" onClick={clearSearchFilters} >
                                        <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                        Clear filters</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <SearchGrid searchList={searchList} searchFormData={searchFormData} searchButtonClicked={searchButtonClicked} />
        </> 
    );
}
 
export default UserList;

